const testimonials = {
    testimonials: [
        {
            name: "Benedikt Zipfelmayer",
            title: "",
            org: "SWIETELSKY Tunnelbau GmbH & Co KG",
            message: "Mit Computer Center Lorentschitsch verbindet uns eine jahrelange, erfolgreiche Zusammenarbeit. Wir schätzen die Zuverlässigkeit, die schnelle Reaktions- und Lieferzeit. Auf solche Partner können wir bauen! Die SWIETELSKY Tunnelbau GmbH und Co KG ist eine Tochterfirma des Swietelsky Konzerns, die auf Untertagebauprojekte jeder Art spezialisiert ist. Neben dem klassischen Infrastrukturbau, wie Straßen-, Eisenbahn- und U-Bahntunnel stellt der unterirdische Kraftwerksbau in Form von Druck- und Beileitungsstollen, Druckschächten und Kavernen einen bedeutenden Unternehmenszweig der Swietelsky Tunnelbau dar.",
        },
        {
            name: "Robert Kastner",
            title: "",
            org: "Jobaktuell Jobbörse für Salzburg",
            message: "Kompetente Beratung - man hat als Kunde das Gefühl, dass den Mitarbeiterinnen und Mitarbeitern die Arbeit Spaß macht. Wenn man mit einem alten Gerät kommt und fragt, ob es noch Sinn macht, da noch etwas hineinzuinvestieren, erhält man eine ehrliche Antwort d.h. es wird nicht automatisch ein neues Gerät gebetsmühlenartig angeboten. Erwähnenswert ist auch noch der Newsletter mit starken Angeboten – Hardware und Peripherie, einer der wenigen Newsletter von denen ich mich noch nicht abgemeldet habe."
        },
        {
            name: "Andreas Wass",
            title: "",
            org: "Glas Gasperlmair GmbH",
            message: "Als einer der führenden glasverarbeitenden Betriebe in Österreich überlassen wir auch im IT-Bereich nichts dem Zufall und verlassen uns nunmehr schon seit 10 Jahren auf Computer Center Lorentschitsch. Eine großartige Beratung angefangen bei Verbrauchsmaterialien bis hin zu großen PC-, Drucker- und Serverprojekten gebündelt mit Termintreue und einem perfekten Preis- Leistungsverhältnis macht Computer Center Lorentschitsch zu einem starken Partner.",

        },
        {
            name: "Veton E. Syliqi",
            title: "",
            org: "Salzburg Research F. mbH",
            message: "Salzburg Research ist ein anwendungsorientiertes Forschungsinstitut mit dem Schwerpunkt Informationstechnologien (IT). Das Institut im Eigentum des Landes Salzburg betreibt Forschung auf internationalem Niveau und entwickelt marktrelevante Technologie und Innovationen für Unternehmen, Industrie und Verwaltung. Durch die sorgfältige Auswahl und partnerschaftliche Beziehung zu unseren Lieferanten stellen wir sicher, dass unsere Ergebnisse mit hoher Qualität erzielt werden. Die Computer Center Lorentschitsch GmbH ist seit Jahren unser vertrauenswürdiger Partner für unsere IT-Infrastruktur."
        },
        {
            name: "Christoph Küssel",
            title: "Mag",
            org: "KÜSSEL digital",
            message: "\"Aus der Praxis, für die Praxis\". \n \n Das Team vom Computercenter Lorentschitsch betreut uns nun schon seit vielen Jahren zu unserer vollsten Zufriedenheit. \n \n Wir bekommen hier sowohl praxisnahe Beratung beim Kauf von Hard- und Software als auch persönlichen Technikersupport im Service-/Updatefall - vor Ort und per Fernwartung. \n \n Ein kompetentes, langjähriges Mitarbeiterteam und Konstanz in der überschaubaren Zahl der vertriebenen Handelsmarken gibt uns Sicherheit in der Auswahl unserer EDV. \n \n Vom Verkauf wird uns nichts aufgeschwatzt, die Techniker sind Profis bei denen jeder Handgriff sitzt – hier fühlen wir uns als Kunde. Auch ein paar hundert Kilometer weg von Salzburg. Danke!"
        },
    ]
}

export default testimonials;