import React, { useEffect, useState } from 'react';

import '../../styles/Landing.scss';

function Landing() {

    function redirectToSupport() {
        window.location.href = '/support';
    }

    function redirectToWebshop() {
        window.location.href = 'https://shop.lorentschitsch.at/';
    }

    return (
        <div className='landing-container'>
            <div className="landing">
                <div className="landing-text">
                    <div className="landing-logo"></div>
                    <p>Ihr zuverlässiger Partner für Computerlösungen seit 1978</p>
                </div>
                <div className="support" onClick={redirectToSupport}>
                    <h2>Fernwartung</h2>
                    <div className='support-img'></div>
                </div>
                <div className="webshop" onClick={redirectToWebshop}>
                    <h2>Web-Shop</h2>
                    <div className='webshop-img'></div>
                </div>

            </div>
            <div className="mainbg" onClick={redirectToSupport}></div>
        </div>
    );
}

export default Landing;
