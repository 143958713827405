import React from "react";

import "../../styles/LogoScroller.scss";
import logos from "../../data/logos";

function LogoScroller() {
    console.log(logos);
    return (
        <div className="logo-scroller">
            <div className="logos">
                {logos.map((logo) => (
                    <img
                        key={logo.id}
                        src={logo.img}
                        alt={logo.alt}
                        className="logo"
                    />
                ))}
            </div>
            <div className="logos">
                {logos.map((logo) => (
                    <img
                        key={logo.id}
                        src={logo.img}
                        alt={logo.alt}
                        className="logo"
                    />
                ))}
            </div>

        </div>
    );
}

export default LogoScroller;