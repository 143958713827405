import React from "react";
import "../styles/Error404.scss";




function Error404() {
    return (
        <div className="error404">
        </div>
    );
}

export default Error404;