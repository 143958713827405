import React from "react";
import { useState } from "react";
import "../../styles/Team.scss";
import workers from "../../data/workers";

const WorkerCard = ({ id, name, position, phone, email }) => (
    <div className="worker">
        <div className="worker-card">
            <div className="worker-img" id={id}></div>
            <h3>{name}</h3>
            <p>
                {position} <br />
                {phone && <>Telefon: {phone} <br /></>}
                {email && <>E-Mail: {email}</>}
            </p>
        </div>
    </div>
);

/**
 * Renders the Team component.
 *
 * @component
 * @example
 * // Usage
 * <Team />
 *
 * @returns {JSX.Element} The rendered Team component.
 */
function Team() {

    const tabs = [
        { key: "leader", label: "Geschäftsführung" },
        { key: "accounting", label: "Buchhaltung" },
        { key: "sales", label: "Verkauf" },
        { key: "repair", label: "Technik & Reparatur" },
        { key: "network", label: "Netzwerktechnik" },
        { key: "software", label: "Software" },
        { key: "buying", label: "Einkauf" },
        { key: "logistics", label: "Logistik" },
    ];


    const [selectedTab, setSelectedTab] = useState("leader");


    return (
        <div className={`team ${selectedTab === "leader" ? "reveal" : ""}`}>
            <h1>Unser Team</h1>
            <div className="team-nav reveal">
                <ul>
                    {tabs.map((tab) => (
                        <li
                            key={tab.key}
                            className={selectedTab === tab.key ? "selected" : ""}
                            onClick={() => setSelectedTab(tab.key)}
                        >
                            {tab.label}
                        </li>
                    ))}
                </ul>
            </div>
            {tabs.map(
                (tab) =>
                    selectedTab === tab.key && (
                        <div key={tab.key} className={`${tab.key} ${selectedTab === "leader" ? "reveal tab-selected" : "tab-selected"}`}>
                            {workers[tab.key].map((worker) => (
                                <WorkerCard key={worker.id} {...worker} />
                            ))}
                        </div>
                    )
            )}
        </div>
    );

}

/**
 * tabs.map explained:
 * 
* {tabs.map(
    (tab) =>
    selectedTab === tab.key && (
        <div key={tab.key} className={`${tab.key} ${selectedTab === "leader" ? "reveal tab-selected" : "tab-selected"}`}>
            {workers[tab.key].map((worker) => (
                <WorkerCard key={worker.id} {...worker} />
            ))}
        </div>
    )
)}
    *
    * 1. tabs.map() is used to iterate over the tabs array.
    * 2. For each tab, a div is rendered if the selectedTab is equal to the tab.key.
    * 3. The div has a key attribute set to the tab.key and a className attribute set to a string that contains the tab.key and the selectedTab.
    * 4. Inside the div, the workers array is iterated over using the map() method.
    * 5. For each worker, a WorkerCard component is rendered with the worker object spread into it.
    * 6. The WorkerCard component has a key attribute set to the worker.id and the worker object spread into it.
    * 7. The WorkerCard component is a child of the div.
    * 8. The div is a child of the team div.
    * 9. The team div is returned from the Team component.
    * 10. The Team component is rendered in the Home component.

 */

export default Team;