import React, { useState } from "react";
import "../../styles/Footer.scss";

function Footer() {
   const [showModal, setShowModal] = useState(false);
   const [downloadLink, setDownloadLink] = useState("");
   const [downloadName, setDownloadName] = useState("");

   const handleDownloadClick = (link, name) => {
      setDownloadLink(link);
      setDownloadName(name);
      setShowModal(true);
   };

   const handleClose = () => {
      setShowModal(false);
   };

   const handleDownload = () => {
      setShowModal(false);
      const link = document.createElement("a");
      link.href = downloadLink;
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   };

   return (
      <div className="footer">
         <div className="footer-top">
            <h1
               className="impressum"
               onClick={() => handleDownloadClick("/impressum.pdf", "Lorentschitsch Impressum")}
            >
               Impressum
            </h1>
            <h1
               className="agb"
               onClick={() => handleDownloadClick("/agb.pdf", "Lorentschitsch Allgemeine Geschäftsbedingungen")}
            >
               AGBs
            </h1>
            <h1 className="datenschutz">Datenschutz</h1>
         </div>
         <div className="footer-bottom">
            <h3 className="copyright">&#169; 2024</h3>
            <h4 className="designed-by">
               Website gestaltet und erstellt von <strong>Fabian Fürlinger</strong>
            </h4>
         </div>

         {/* Modal Component */}
         {showModal && (
            <div className="modal-overlay">
               <div className="modal-content">
                  <h2>Wollen Sie dieses PDF herunterladen?</h2>
                  <button onClick={handleDownload}>Ja, herunterladen</button>
                  <button onClick={handleClose}>Nein</button>
               </div>
            </div>
         )}
      </div>
   );
}

export default Footer;
