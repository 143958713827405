const prices = [
    {
        id: "network/printer",
        price_h: 119 
    },
    {
        id: "repair",
        price_h: 105,
    },
    {
        id: "software",
        price_h: 125,
    },
    {
        id: "young-technician",
        price_h: 75,
    },
]

export default prices;