import React from "react";
import "../../styles/Testimonials.scss";
import testimonials from "../../data/testimonials";

const Testimonial = ({ name, title, org, message }) => (
    <div className="testimonial">
        <div className="testimonial-icon"></div>
        <div className="testimonial-text">
            <p>{message}</p>
        </div>
        <div className="testimonial-author">
            <p>- {title && title + ", "} {name}, {org}</p>
        </div>
        <div className="testimonial-line"></div>

        <div className="testimonial-mobile-next" onClick={handleMobileNext}>
            <p>Nächste Rezession</p>
        </div>
    </div>
);


function handleMobileNext() {
    const testimonials = document.querySelector(".testimonials");

    const allTestimonials = testimonials.querySelectorAll(".testimonial");

    const itemWidth = allTestimonials[0].offsetWidth + 32; // 32 is the margin


    console.log("scrolling");
}


const Testimonials = () => {
    return (
        <>
            <div className="testimonials-section">
                <h1 className="testimonials-h1 reveal">Kundenmeinungen</h1>
                <div className="testimonials-container reveal">
                    <div className="testimonials-text">
                        <h1>Was unsere Kunden sagen</h1>
                        <p>
                            Dinge mit anderen Augen zu sehen ist ein Teil unserer Erfolgsgeschichte. Darum vertrauen wir nicht nur auf eine gesunde Selbsteinschätzung, sondern nehmen das Feedback unserer Kunden sehr ernst. Hier finden Sie einen kleinen Auszug davon, wie unsere Arbeit ankommt.
                        </p>
                    </div>
                    <div className="testimonials">
                        {testimonials.testimonials.map((testimonial) => (
                            <Testimonial {...testimonial} />
                        ))}

                    </div>

                </div>
            </div>
        </>
    );
};

export default Testimonials;