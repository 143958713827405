import { useRef } from "react";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "../../styles/Navbar.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEffect } from "react";

function Navbar() {
    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle(
            "responsive_nav"
        );
    };

    useEffect(() => {
        const nav = document.querySelector('.navbar');
        if (nav) {
            window.addEventListener('scroll', () => {
                if (window.scrollY > 50) {
                    nav.classList.add('sticky');
                } else {
                    nav.classList.remove('sticky');
                }
            });
        }
    }, []);

    return (
        <header className="navbar">
            <img src="./img/misc/logo.png" alt="" />
            <nav ref={navRef} className="responsive_nav">
                <a href="#services" onClick={showNavbar}>Leistungen</a>
                <a href="#team">Unser Team</a>
                <a href="#history">Historie</a>
                <a href="#testimonials">Kundenmeinungen</a>
                <a href="#contact">Kontakt</a>
                <button
                    className="nav-btn nav-close-btn"
                    onClick={showNavbar}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </nav>
            <button
                className="nav-btn"
                onClick={showNavbar}>
                <FontAwesomeIcon icon={faBars} />
            </button>
        </header>
    );
}

export default Navbar;