const workers = {
    leader: [
        {
            id: "karl",
            name: "Ing. Karl Lorentschitsch",
            position: "Geschäftsführung",
            phone: "+43 / (0)662 / 660505-19",
            email: "kl@lorentschitsch.at",
        },
        {
            id: "leona",
            name: "Leona Lorentschitsch",
            position: "BA, MBA Prokuristin ",
        },
        {
            id: "gerald",
            name: "Ing. Gerald Fürlinger",
            position: "Geschäftsführung",
            phone: "+43 / (0)662 / 660505-17",
            email: "fg@lorentschitsch.at",
        },
        {
            id: "olcay",
            name: "Olcay Gündüz",
            position: "Prokurist, Mesonic Software Spezialist",
            phone: "+43 / (0)662 / 660505-52",
            email: "guenduez@lorentschitsch.at",
        },
    ],
    accounting: [
        {
            id: "ines",
            name: "Ines Fürlinger",
            position: "Buchhaltung",
            phone: "+43 / (0)662 / 660505-61",
            email: "buchhaltung@lorentschitsch.at",
        },
    ],
    sales: [
        {
            id: "stephan",
            name: "Stephan Titze",
            position: "Vertriebsleiter",
            phone: "+43 / (0)662 / 660505-24",
            email: "titze@lorentschitsch.at",
        },
        {
            id: "maria",
            name: "Maria Höfer-Frischling",
            position: "Verkauf, Systemvertrieb, Webshop",
            phone: "+43 / (0)662 / 660505-72",
            email: "hfm@lorentschitsch.at",
        },
        {
            id: "christian",
            name: "Christian Riedl",
            position: "Verkauf, Systemvertrieb",
            phone: "+43 / (0)662 / 660505-22",
            email: "riedl@lorentschitsch.at",
        },
        {
            id: "stephanH",
            name: "Stephan Hautz",
            position: "Shopverkauf und Beratung",
            phone: "+43 / (0)662 / 660505-26",
            email: "hautz@lorentschitsch.at",
        },
        {
            id: "darko",
            name: "Darko Radic",
            position: "Lehrling, Systemvertrieb",
            phone: "+43 / (0)662 / 660505-25",
            email: "radic@lorentschitsch.at",

        }
    ],
    repair: [
        {
            id: "andreas",
            name: "Andreas Renoth",
            position: "PC- und Notebook-Reperaturen",
            phone: "+43 / (0)662 / 660505-45",
            email: "renoth@lorentschitsch.at",
        },
        {
            id: "natalia",
            name: "Natalia Mukieieva",
            position: "PC- und Notebook-Reperaturen",
            phone: "+43 / (0)662 / 660505",
            email: "nm@lorentschitsch.at",
        },
        {
            id: "cem",
            name: "Cem Sahin",
            position: "Technik Innendienst",
            phone: "+43 / (0)662 / 660505-41",
            email: "sahin@lorentschitsch.at",
        },

    ],
    network: [
        {
            id: "slobodan",
            name: "Slobodan Petrovic",
            position: "Technischer Leiter",
            phone: "+43 / (0)662 / 660505-35",
            email: "petrovic@lorentschitsch.at",
        },
        {
            id: "lukas",
            name: "Lukas Arnold",
            position: "Netzwerktechnik, Außen & Innendienst",
            phone: "+43 / (0)662 / 660505-36",
            email: "arnold@lorentschitsch.at",
        },
        {
            id: "sebastian",
            name: "Sebastian Weickl",
            position: "Netzwerktechnik, Außen & Innendienst",
            phone: "+43 / (0)662 / 660505-38",
            email: "weickl@lorentschitsch.at",
        },
        {
            id: "thomas",
            name: "Thomas Gschwandtner",
            position: "PC & Notebook-Reparaturen, Netzwerktechnik Hotline",
            phone: "+43 / (0)662 / 660505-34",
            email: "gschwandtner@lorentschitsch.at",
        },
        {
            id: "lukasG",
            name: "Lukas Gobl",
            position: "Netzwerktechnik Innendienst, Hotline & Teamkoordination",
            phone: "+43 / (0)662 / 660505-33",
            email: "gobl@lorentschitsch.at",
        },
        {
            id: "martin",
            name: "Martin Lasar",
            position: "Technik Innendienst",
            phone: "+43 / (0)662 / 660505-42",
            email: "lasar@lorentschitsch.at",
        },
        {
            id: "simon",
            name: "Simon Hirscher",
            position: "Netzwerktechnik, Außen & Innendienst",
            phone: "+43 / (0)662 / 660505-36",
            email: "arnold@lorentschitsch.at",
        },
    ],
    software: [
        {
            id: "olcay",
            name: "Olcay Gündüz",
            position: "Prokurist, Mesonic Software Spezialist",
            phone: "+43 / (0)662 / 660505-52",
            email: "guenduez@lorentschitsch.at",
        },
        {
            id: "stefan",
            name: "Stefan Innerlohinger",
            position: "Etron Software Support, Netzwerktechnik, Außen & Innendienst",
            phone: "+43 / (0)662 / 660505-51",
            email: "innerlohinger@lorentschitsch.at",
        },
        {
            id: "fabian",
            name: "Fabian Fürlinger",
            position: "Programmierung, Webdesign",
            phone: "+43 / (0)662 / 660505",
            email: "ff@lorentschitsch.at",
        },
    ],
    buying: [
        {
            id: "steven",
            name: "Steven Dragar",
            position: "Leiter Einkauf",
            phone: "+43 / (0)662 / 660505-81",
            email: "einkauf@lorentschitsch.at",
        },
    ],
    logistics: [
        {
            id: "robert",
            name: "Robert Mairegger",
            position: "Leiter Logistik & Lager",
            phone: "+43 / (0)662 / 660505-82",
            email: "mairegger@lorentschitsch.at",
        },
        {
            id: "christopher",
            name: "Christopher Porter",
            position: "Logistik & Lager",
            phone: "+43 / (0)662 / 660505",
            email: "porter@lorentschitsch.at",
        },
    ],
};

export default workers;