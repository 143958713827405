import React from "react";
import "../../styles/Services.scss";
import services from "../../data/services";

function Service({ id, title, description }) {
    return (
        <div className="service">
            <div className="service-img-bg">
                <div className="service-img" id={id}></div>
            </div>
            <div className="service-card">
                <div className="service-text">
                    <h1>{title}</h1>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}

function Services() {
    return (
        <>
            <h1 className="services-h1 reveal"> Unsere Leistungen</h1>

            <div className="services-container">
                <div className="services-bg reveal">
                    <div className="services-bg-img" id="services-bg"> </div>
                </div>
                <div className="services reveal">
                    {Object.values(services).map((service) => (
                        <Service {...service} />
                    ))}
                </div>
            </div>
        </>
    );
}

export default Services;