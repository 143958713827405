const histroy = [
   {
      id: "founding",
      title: 'Unternehmens-\ngründung',
      date: '1978',
      content: 'Gründung des Unternehmens durch Ing. Karl H. Lorentschitsch'
   },
   {
      id: "new-headquarters",
      title: 'Neue Firmenzentrale',
      date: '1981',
      content: 'Eröffnung der neuen Firmenzentrale in Salzburg Gnigl mit eigenem Shop'
   },
   {
      id: "new-branch",
      title: 'Neue Filiale',
      date: '1983',
      content: 'Eröffnung einer Filiale in der Wolf Dietrich Straße für Privatkunden'
   },
   {
      id: "company-newspaper",
      title: 'Firmenzeitung',
      date: '1987',
      content: 'Erste Firmenzeitung "Lorentschitsch Report" erscheint'
   },
   {
      id: "gmbh",
      title: 'Lorentschitsch GmbH',
      date: '1994',
      content: 'Gründung der Computer Center Lorentschitsch GmbH'
   },
   {
      id: "slobi",
      title: 'Start der Lehrlingsausbildung',
      date: '1995',
      content: '1995 als erster EDV Lehrling beginnt Slobodan Petrovic seine Ausbildung im Betrieb - heute ist er Technischer Leiter'
   },
   {
      id: "web",
      title: 'Webauftritt',
      date: '1999',
      content: 'Erster professioneller Webauftritt unter www.lorentschitsch.at'
   },
   {
      id: "schulshop",
      title: 'Schulshop',
      date: '2003',
      content: 'www.schulshop.at startet als Salzburger Online-Shop speziell für Schulen und LehrerInnen'
   },
   {
      id: "new-building",
      title: 'Neuer Standort',
      date: '2004',
      content: 'Umzug in ein komplett renoviertes, familieneigenes Gebäude'
   },
   {
      id: "lehrbetrieb",
      title: 'Bester Lehrbetrieb',
      date: '2006',
      content: '2006 erhält Lorentschitsch die Auszeichnung als bester Lehrbetrieb in Salzburg'
   },
   {
      id: "second-manager",
      title: 'Investition in die Zukunft',
      date: '2010',
      content: 'Übernahme von Geschäftsanteilen durch Ing. Gerald Fürlinger'
   },
   {
      id: "new-showroom",
      title: 'Neuer Schauraum',
      date: '2013',
      content: 'Komplettumbau und Modernisierung unseres Kundenbereichs inklusive Eröffnung unserer Sofortreparaturwerkstatt'
   }

];

export default histroy; 