import React, { useState, useEffect } from "react";
import "../../styles/History.scss";
import histroy from "../../data/history";

function History() {
    const [currentDate, setCurrentDate] = useState(0);


    const nextImage = () => {
        setCurrentDate((prevDate) => (prevDate + 1) % histroy.length);
    };

    const previousImage = () => {
        setCurrentDate((prevDate) => (prevDate - 1 + histroy.length) % histroy.length);
    };


    return (
        <div>
            <h1 className="history-h1">Unsere Geschichte</h1>

            <div className="history">
                <button className="history-button history-button-left" onClick={previousImage}>
                    <div className="history-icon-left"></div>
                </button>

                <div className="date-carousel">
                    {histroy.map((item, index) => (
                        <div
                            key={item.id}
                            className={`${item.id} date ${index === currentDate ? "history-active" : index === currentDate - 1 ? "history-active-left" : index === currentDate + 1 ? "history-active-right" : ""}`}
                        >
                            <div className="mobile-click-me">
                                <h2>Klicken Sie auf das Bild, um mehr zu erfahren</h2>
                            </div>
                            <div className="date-number-container">
                                <h2>{item.date}</h2>
                            </div>
                            <h1>{item.title}</h1>
                            <p>{item.content}</p>
                        </div>
                    ))}
                </div>

                <button className="history-button history-button-left-mobile" onClick={previousImage}>
                    <div className="history-icon-left"></div>
                </button>

                <button className="history-button history-button-right" onClick={nextImage}>
                    <div className="history-icon-right"></div>
                </button>
            </div>

            <div className="timeline">
                {histroy.map((item, index) => (
                    <div
                        key={item.id}
                        className={`timeline-dot ${index === currentDate ? "timeline-dot-active" : ""}`}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default History;
