import React from "react";
import { Link } from "react-router-dom";
import "../styles/Support.scss";
import support from "../data/support";

function SupportGroup({ supportItem }) {
    return (
        <div className="support-group">
            <h2>{supportItem.title}</h2>
            <div className="support-pricing">
                <p>Preis pro Stunde: <span>{supportItem.price_h}€</span></p>
                <p>Preis pro Stunde inkl. Mwst: <span>{supportItem.price_h_mwst}€</span></p>
                <p>Preis pro 15 Minuten: <span>{supportItem.price_15}€</span></p>
                <p>Preis pro 15 Minuten inkl. Mwst: <span>{supportItem.price_15_mwst}€</span></p>
            </div>
            <div className="support-workers">
                {supportItem.workers && supportItem.workers.map((worker) => {
                    return (
                        <div className="support-worker" key={worker.id}>
                            <div className="support-worker-card">
                                <img src={worker.img} alt={worker.name} />
                                <h3>{worker.name}</h3>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function Support() {
    return (
        <div>
            <div className="support-container">
                <h1>Fernwartung</h1>
                <div className="support-fernwartung">
                    {support.slice(0, 4).map((supportItem) => {
                        return (
                            <SupportGroup key={supportItem.id} supportItem={supportItem} />
                        );
                    })}
                </div>
                <div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                </div>
            </div>
        </div>
    );
}


export default Support;