import React from "react";
import "../../styles/Contact.scss";

const contacts = [
    {
        name: "location",
        title: "Kommen Sie vorbei!",
        text: "Rott-Au 20, 5020 Salzburg",
    },
    {
        name: "phone",
        title: "Rufen Sie uns an!",
        text: "+43 / (0)662 / 660505",
    },
    {
        name: "email",
        title: "Schreiben Sie uns!",
        text: "office@lorentschitsch.at",
    },
    {
        name: "openingTimes",
        title: "Unsere Öffnungszeiten",
        text: "Montag bis Freitag: 08:00 - 17:00 Uhr",
    }
];

function ContactCard({ name, title, text }) {
    return (
        <div className={`contact`}>
            <div className="contact-img" id={name}></div>
            <div className="contact-text">
                <h1>{title}</h1>
                {text === "office@lorentschitsch.at" ? (
                    <a href={`mailto:${text}`}>{text}</a>
                ) : text === "Rott-Au 20, 5020 Salzburg" ? (
                    <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(text)}`}>{text}</a>
                ) : (
                    <p>{text}</p>
                )}
            </div>
        </div>
    );
}

function Contact() {
    return (
        <>
            <div className="contact-h1">
                <h1>Kontakt</h1>
            </div>
            <div className="contact-container">
                {contacts.map((contact) => (
                    <ContactCard {...contact} />
                ))}
            </div>
        </>
    );
}

export default Contact;